




















import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "industry"
})
export default class Industry extends Vue {
	private parentData = {
		titleName: "能力开放",
		dec: "1000+潜在客户曝光，各种硬件一键服务接入",
		bannerImg: require("../../assets/images/banner.png"),
		img: require("../../assets/images/banner_icon4.png"),
		imgWidth: "402px",
		imgHeight: "233px"
	};

	private industryList = [
		{
			name: "误报消除算法",
			img: require("../../assets/images/industry1.png")
		},
		{
			name: "AI辅助决策",
			img: require("../../assets/images/industry2.png")
		},
		{
			name: "全面开放API",
			img: require("../../assets/images/industry3.png")
		}
	];

	private created() {
		this.$emit("getChildrenData", this.parentData);
	}
}
